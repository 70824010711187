import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import Letras_unicas from 'components/letras-unicas'
import $ from 'jquery';
import AnimatedHeading from 'components/animated-heading'
import fondo from 'sections/images/fondos/f3.jpg'
import Idioma from 'sections/idiomas/contents.json'
import Cookies from 'universal-cookie';
class Preguntas extends React.Component {
  
  constructor(props){
    const cookies = new Cookies();
    super(props)
    let title=""
    let a=cookies.get('idioma')
    if(a=="ingles"){
        title=Idioma.ingles.about_us;
    }else{
        title=Idioma.espanol.about_us;
    }

    this.state={
      titulo:title
    }
   
  }

    shouldComponentUpdate() {
        return false
    }
   

    render() {
        const Section = styled.section`
            position: relative;
            overflow: hidden;
           
            background-image: url(${fondo});
            background-size: cover;
            background-repeat: no-repeat;
           
            .heading {
                width: 100%;
            }
          }
        `

        const ServiceContainer = styled.div`
          
            padding: 100px 0;
        `


        const ServiceElement = styled.div`
            margin-bottom: 20px;
            text-align: center;
            padding: 40px;
            border-radius: 20px;
            transition: .2s;
            &:hover {
                background-color: #000;
                box-shadow: 0 28px 60px rgb(4, 229, 229, .1);
                transform: translateY(-10px);
            }
        `
         const ServiceHeading = styled.h4`
            font-size: 20px;
            -webkit-text-stroke: 1px black;
            font-weight: 500;
            font-family: gameovercre;
            color: #00FFFF;
        `
        const ServiceSeparator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #00FF00;
            margin-bottom: 10px;
            margin: auto;
         `
        const ServiceIcon = styled.div`
            margin-bottom: 20px;
            img {
                max-height: 70px;
            }
        `

        const ServiceList = styled.ul`
            padding: 0;
            margin: 11px 0 0 0;
        `

        const ServiceListElement = styled.li`
            list-style: none;
            -webkit-text-stroke: 1px black;
            color: #fff;
            font-weight: 300;
            font-size: 20px;
            margin: 5px 0px;
            &::before {
                display: inline-block;
                content: '';
                border-radius: 2.5px;
                height: 5px;
                width: 5px;
                margin-right: 10px;
                background-color:#04e5e5;
                position: relative;
                bottom: 2.5px;
            }
        `
      function aparecer(id){
        desaparecer()
            var ids="#"+id;
            $(ids).show()
          
        }

    function desaparecer(){
        for (let index = 1; index <= 6; index++) {
            var ids="#p"+index
            $(ids).hide()
        }
    }
          
        

        return(
            <Section id="services">
                <ServiceContainer>
                    <Container>
                        <AnimatedHeading text="Questions" />
                        <Row className='justify-content-center'>
                            <Col md={5}>
                                <AnimationContainer animation="fadeInLeft" delay={200} >
                                        <ServiceElement onClick={()=>aparecer("p1")} style={{cursor:"pointer"}} className="bg-dark">
                                            <ServiceIcon>
                                                  
                                            </ServiceIcon>
                                            <ServiceHeading >
                                               {this.state.titulo.t_pregunta1}
                                            </ServiceHeading>
                                            <ServiceSeparator/>
                                            <ServiceList>
                                                <ServiceListElement id='p1' style={{display:"none"}}>
                                                {this.state.titulo.c_pregunta1}
                                                </ServiceListElement>
                                            </ServiceList>
                                        </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={5}>
                                <AnimationContainer animation="fadeInDown"  delay={400}>
                                    <ServiceElement onClick={()=>aparecer("p2")} style={{cursor:"pointer"}} className="bg-dark">
                                        <ServiceIcon>
                                                
                                        </ServiceIcon>
                                        <ServiceHeading >
                                        {this.state.titulo.t_pregunta2}
                                        </ServiceHeading>
                                        <ServiceSeparator/>
                                        <ServiceList>
                                            <ServiceListElement id='p2' style={{display:"none"}}>
                                            {this.state.titulo.c_pregunta2}
                                            </ServiceListElement>
                                            
                                        </ServiceList>
                                    </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={5}>
                                <AnimationContainer animation="fadeInRight" delay={600}>
                                    <ServiceElement onClick={()=>aparecer("p3")} style={{cursor:"pointer"}} className="bg-dark">
                                        <ServiceIcon>
                                                
                                        </ServiceIcon>
                                        <ServiceHeading >
                                        {this.state.titulo.t_pregunta3}
                                        </ServiceHeading>
                                        <ServiceSeparator/>
                                        <ServiceList>
                                            <ServiceListElement id='p3' style={{display:"none"}}>
                                            {this.state.titulo.c_pregunta3}                                            </ServiceListElement>
                                            
                                        </ServiceList>
                                    </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={5}>
                                <AnimationContainer animation="fadeInLeft" delay={800}>
                                    <ServiceElement onClick={()=>aparecer("p4")} style={{cursor:"pointer"}} className="bg-dark">
                                        <ServiceIcon>
                                                
                                        </ServiceIcon>
                                        <ServiceHeading >
                                        {this.state.titulo.t_pregunta4}                                        </ServiceHeading>
                                        <ServiceSeparator/>
                                        <ServiceList>
                                            <ServiceListElement id='p4' style={{display:"none"}}>
                                            {this.state.titulo.c_pregunta4}                                            </ServiceListElement>
                                           
                                        </ServiceList>
                                    </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={5}>
                                <AnimationContainer animation="fadeInUp" delay={1000}>
                                    <ServiceElement  onClick={()=>aparecer("p5")} style={{cursor:"pointer"}} className="bg-dark">
                                        <ServiceIcon>
                                                
                                        </ServiceIcon>
                                        <ServiceHeading>
                                        {this.state.titulo.t_pregunta5}                                        </ServiceHeading>
                                        <ServiceSeparator/>
                                        <ServiceList>
                                            <ServiceListElement id='p5' style={{display:"none"}}>
                                            {this.state.titulo.c_pregunta5}
                                            </ServiceListElement>
                                           
                                        </ServiceList>
                                    </ServiceElement>
                                </AnimationContainer>
                            </Col>
                            <Col md={5}>
                                    <AnimationContainer animation="fadeInRight" delay={1200}>
                                        <ServiceElement onClick={()=>aparecer("p6")} style={{cursor:"pointer"}} className="bg-dark">
                                            <ServiceIcon>
                                                   
                                            </ServiceIcon>
                                            <ServiceHeading >
                                            {this.state.titulo.t_pregunta6}
                                            </ServiceHeading>
                                            <ServiceSeparator/>
                                            <ServiceList>
                                                <ServiceListElement id='p6' style={{display:"none"}}>
                                                {this.state.titulo.c_pregunta6}
                                                </ServiceListElement>
                                                
                                            </ServiceList>
                                        </ServiceElement>
                                    </AnimationContainer>
                            </Col>
                        </Row>
                    </Container>
                </ServiceContainer>
            </Section>
        )
    }

}

export default props => (
    <StaticQuery
      query={graphql`
      query {
        background: file(relativePath: {eq: "bg2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              src
            }
          }
        }
        web: file(relativePath: {eq: "icons/web.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        mobile: file(relativePath: {eq: "icons/mobile.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        seo: file(relativePath: {eq: "icons/seo2.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        email: file(relativePath: {eq: "icons/email.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        ui: file(relativePath: {eq: "icons/sketch.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        network: file(relativePath: {eq: "icons/network.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }      
      `}
      render={({ 
        background,
        web,
        mobile,
        seo,
        email,
        ui,
        network}) => <Preguntas  
        background={background}
        web={web}
        mobile={mobile}
        seo={seo}
        email={email}
        ui={ui}
        network={network}
        {...props} />}
    />
  )